:root {
  --main-bg-color: #f55fe9fc;
}

body {
  /* overflow: hidden; */
  background: black;
  font-family: "Kdam Thmor Pro";
}

.rs-btn {
  margin: 3px;
}

.rs-btn-icon {
  margin: 0;
}

.rs-btn-primary {
  background-color: var(--main-bg-color) !important;
}

.rs-panel {
  background: #121212 !important;
}

.rs-slider-progress-bar {
  background: var(--main-bg-color) !important;
}

.rs-slider-handle:before {
  border-color: var(--main-bg-color) !important;
}

audio {
  position: absolute;
  top: 0;
  left: 0;
}

canvas {
  background: #000;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
}

.dg .main,
.dg .ac {
  z-index: 100;
}

.cr .color>.selector {
  z-index: 1000;
}

.dg .c input[type=text] {
  height: 12px;
}

.panel-info rs-panel {
  backdrop-filter: blur(30px);
  backdrop-filter: invert(80%);
  background-color: rgba(255, 255, 255, .5);
  -webkit-backdrop-filter: blur(2em);
  backdrop-filter: blur(2em);
  opacity: .2;
  border-radius: 20px;
  text-align: "center";
}

.rs-drawer-content {
  background-color: rgba(0, 0, 0, 0);
}

.rs-drawer-right {
  backdrop-filter: blur(20px) brightness(150%);
}

.rs-drawer-backdrop {
  backdrop-filter: blur(5px) brightness(50%);
  background-color: rgba(0, 0, 0, 0);
}

.btn-glass {
  height: 30px;
  min-width: 50px;
}

.rs-drawer-header-close,
.btn-glass {
  background: #FFFFFF22;
  color: #111;
}

.rs-drawer-header-close.selected,
.btn-glass.selected {
  background: var(--main-bg-color);
  color: #FFF;
}

.rs-drawer-header-close:hover,
.btn-glass:hover {
  background: #FFFFFF44;
  color: #FFF;
}

.guest {
  font-size: .8em;
  color: #00000088;
}

h2,
h3 {
  color: #111111AA;
}

h3 {
  font-size: 1.5em;
}


.rs-radio-checker label {
  color: #111111AA !important;
}

.rs-radio-group {
  background: #FFFFFF44;
}

.rs-radio-checked .rs-radio-checker label {
  border-color: var(--main-bg-color) !important;
  color: var(--main-bg-color) !important;
}

.rs-radio-group-picker {
  border: 2px #FFFFFF22 solid;
}

.rs-drawer-header .rs-drawer-header-close {
  right: 15px;
  left: auto;
}

.about p {
  margin-bottom: 30px;
  filter: drop-shadow(#000000aa 0px 0px 3px);
}

.about b {
  color: #ACF;
  font-weight: normal;
}

.about u {
  color: #CFC;
  text-decoration: none;
  font-style: normal;
}

.about i {
  color: #FAA;
  font-style: normal;
}

.rs-popover {
  background: #000000;
}

.rs-popover h3 {
  color: white;
}

.hide {
  opacity: 0;
  transition-duration: .3s;
}

.home-bg {
  background-image: radial-gradient(circle, #cb2589, #a52b89, #7f2e83, #5a2e78, #372a68, #302357, #291c46, #211536, #221026, #1d0a19, #14050d, #000000);
}

.home-img {
  transition: transform .3s cubic-bezier(0.5, 2.8, 0.3, 0.5);
  height: "512px";
  filter: drop-shadow(0px 0px 40px #00000088);
  cursor: "pointer";
  z-index: 1000;
  position: relative;
  cursor: pointer;
  border-radius: 5px;
}

.home-img:hover {
  transform: scale(.95);
  filter: drop-shadow(0px 0px 15px #000000AA);
}

.home-img:active {
  transform: scale(.90);
  filter: drop-shadow(0px 0px 5px #000000EE);
}